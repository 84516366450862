import React from "react";
import useWindowSize from "../../hooks/useWindowSize";
import styles from "./DeliveryTypes.module.scss";

const DeliveryTypesType = (props) => {
  const windowSize = useWindowSize();
  const tabletSize = 991;

  return (
    <div className={props.className}>
      <div className={styles["img-wrapper"]}>
        <img src={props.imageSrc} alt="" />
      </div>
      {/* { windowSize.width < tabletSize ? <div className={styles.space} /> : '' } */}
      <p dangerouslySetInnerHTML={{ __html: props.children }} />
    </div>
  );
};

export default DeliveryTypesType;
