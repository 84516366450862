import React from "react";
import SVG from "react-inlinesvg";
import logoSVG from "../../assets/images/logo.svg";

import styles from "./Header.module.scss";
import SocialLinks from "../SocialLinks";

const Header = () => {
  return (
    <header className={styles.header}>
      <SVG src={logoSVG} alt="" className={styles.logo} />

      {/* <SocialLinks showHelperText={true} className={styles.links} /> */}
      <SocialLinks types="header" />
    </header>
  );
};

export default Header;
