import React from "react";
import SocialLinks from "./SocialLinks";
import styles from "./SocialLinks.module.scss";

function index({ types }) {
  const Location = {
    header: "header",
    footer: "footer",
  };

  return (
    <div className={styles.SocialLinks_container}>
      <SocialLinks type={Location[types]} icon="calendar">
        {types == "header" ? "Закажите онлайн" : "Забронировать"}
      </SocialLinks>
      <SocialLinks type={Location[types]} icon="phone">
        +48 573 666 398
      </SocialLinks>
      <SocialLinks type={Location[types]} icon="telegram">
        Телеграм
      </SocialLinks>
    </div>
  );
}

export default index;
