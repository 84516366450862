import React from "react";
import sizeSVG from "../../assets/images/garage/1.svg";
import weigthSVG from "../../assets/images/garage/2.svg";
import mercedesImage from "../../assets/images/garage/mercedes.png";
import mercedesMobileImage from "../../assets/images/garage/mercedesMobile.png";

import styles from "./Garage.module.scss";
import useWindowSize from "../../hooks/useWindowSize";

const Garage = () => {
  const sizes = useWindowSize();
  const tabletSize = 991;

  return (
    <section className={styles.garage}>
      <div className={styles.garage__text}>
        <p className={styles.garage__desc}>
          В нашем парке сейчас два современных автомобиля Mercedes Sprinter
        </p>
        <div className={styles["garage__desc-options"]}>
          <p>
            <img src={sizeSVG} alt="" />
            430 × 175 × 190 см
          </p>
          <p>
            <img src={weigthSVG} alt="" />
            1100 кг
          </p>
        </div>
        {sizes.width > tabletSize && (
          <img
            className={styles["garage-car"]}
            src={mercedesImage}
            alt="mercedes"
          />
        )}
      </div>
      {sizes.width <= tabletSize && (
        <div className={styles["img-wrapper"]}>
          <img
            className={styles["garage-car"]}
            src={mercedesMobileImage}
            alt="mercedes"
          />
        </div>
      )}
    </section>
  );
};

export default Garage;
