import React from "react";
import map from "../../assets/images/europe.jpg";

import styles from './Locations.module.scss'
import useWindowSize from "../../hooks/useWindowSize";

const Locations = (props) => {
    const windowSize = useWindowSize()
    const tabletSize = 991

    return (
        <section className={styles.locations}>
            <p>Работаем по Варшаве,{ windowSize.width < tabletSize && <br /> } и по маршрутам Варшава — любая точка ЕС</p>
            <img className={styles["locations-map"]} src={map} alt="" />
        </section>
    )
}

export default Locations