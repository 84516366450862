import React from "react";
import SVG from "react-inlinesvg";

import style from "./SocialLinks.module.scss";

import telegram from "../../assets/images/SocialLinks/telegram.svg";
import phone from "../../assets/images/SocialLinks/phone.svg";
import calendar from "../../assets/images/SocialLinks/calendar.svg";

function index({ children, type, icon }) {
  const SocialLinksIcon = {
    telegram: {
      telegram,
      styles: { marginRight: "7px" },
      href: "https://t.me/obutsko",
    },
    phone: {
      phone,
      styles: { margin: "1px 10px 0px 2px" },
      href: "tel:+48573666398",
    },
    calendar: {
      calendar,
      styles: { margin: "4px 4.5px 0px 0px" },
      href: "https://calendly.com/freedom_trans",
      target: "_blank",
    },
  };

  const className = {
    header: style.SocialLinks_header,
    footer: style.SocialLinks_footer,
  };
  return (
    <a
      className={[className[type], style.SocialLinks].join(" ")}
      href={SocialLinksIcon[icon]["href"]}
      target={SocialLinksIcon[icon]["target"]}
    >
      <SVG
        src={SocialLinksIcon[icon][icon]}
        alt=""
        style={SocialLinksIcon[icon]["styles"]}
      />
      {children}
    </a>
  );
}

export default index;
