import React from "react";
import SVG from "react-inlinesvg";
import smallLogoSVG from "../../assets/images/smalllogo.svg";
import styles from "./Footer.module.scss";
import SocialLinks from "../SocialLinks";

const Footer = () => {
  return (
    <footer className={styles.footer__container}>
      <div className={styles.footer}>
        <div className={styles.footer_links}>
          <SVG src={smallLogoSVG} />
          <SocialLinks types="footer" />
        </div>

        <div className={styles["footer__hack"]}>
          <div className={styles["footer__hack-column"]}>
            <p>FREEDOM TRANS SPÓŁKA Z OGRANICZONĄ ODPOWIEDZIALNOŚCIĄ </p>
            <div className={styles["footer__hack-column"]}>
              <p>NIP: 7011074247</p>
              <p>REGON: 521225371</p>
            </div>
          </div>

          <div className={styles["footer__hack-column"]}>
            <p>ul. FRANCISZKA KSAWEREGO DMOCHOWSKIEGO,</p>
            <p>nr 4, lok. 2, miejsc. WARSZAWA, kod 00-427,</p>
            <p>poczta WARSZAWA, kraj POLSKA</p>
          </div>
          <div className={styles["footer__hack-column"]}>
            <p>PKO Bank Polski S.A. </p>
            <p>PL 69 1020 1013 0000 0302 0468 0700</p>
          </div>
        </div>
      </div>
      {/* <SocialLinks showHelperText={false} /> */}
    </footer>
  );
};

export default Footer;
