import React from "react";

import DeliveryTypes from "./components/DeliveryTypes";
import Header from "./components/Header";
import Advantages from "./components/Advantages";
import Locations from "./components/Locations";
import Footer from "./components/Footer";
import Garage from "./components/Garage";

import "normalize.css";
import "./App.scss";

function App() {
  return (
    <div className="main">
      <Header />
      <DeliveryTypes />
      <Advantages />
      <Locations />
      <Garage />
      <Footer />
    </div>
  );
}

export default App;
