import SVG from "react-inlinesvg";
import arrowSVG from "../../assets/images/arrow.svg";
import React from "react";

import styles from './Advantages.module.scss'

const Advantages = (props) => {
    return (
        <section className={styles.advantages}>
            <p className={styles.advantages__desc}>
                Мы — молодая транспортная компания, основанная беларусами в Варшаве в
                начале 2022 года. Имеем за плечами 10-летний опыт работы
                в грузоперевозках и уже десятки довольных клиентов в Польше.
            </p>
            <div className={styles.advantages__benefits}>
                <div className={styles["advantages__benefits-column"]}>
                    <p>
                        <SVG src={arrowSVG} />
                        Честная цена
                    </p>
                    <p>
                        <SVG src={arrowSVG} />
                        Короткие сроки
                    </p>
                    <p>
                        <SVG src={arrowSVG} />
                        Связь 24/7
                    </p>
                    <p>
                        <SVG src={arrowSVG} />
                        Помощь при погрузке и разгрузке
                    </p>
                </div>
                <div className={styles["advantages__benefits-column"]}>
                    <p>
                        <SVG src={arrowSVG} />
                        Гарантия сохранности груза
                    </p>
                    <p>
                        <SVG src={arrowSVG} />
                        Опытные водители
                    </p>
                    <p>
                        <SVG src={arrowSVG} />
                        Работа с юридическими и физическими лицами
                    </p>
                    <p>
                        <SVG src={arrowSVG} />
                        Пунктуальность и надежность
                    </p>
                </div>
            </div>
        </section>
    )
}

export default Advantages