import React, { useMemo } from "react";

import couchAndLampSVG from "../../assets/images/icons/couch.png";
import desktopComputerSVG from "../../assets/images/icons/desktop.png";
import packageSVG from "../../assets/images/icons/package.png";
import brickSVG from "../../assets/images/icons/brick.png";
import dogSVG from "../../assets/images/icons/dog.png";
import cocktailSVG from "../../assets/images/icons/cocktail.png";
import ladderSVG from "../../assets/images/icons/ladder.png";
import busSVG from "../../assets/images/icons/bus.png";
import DeliveryTypesType from "./_Type";
import styles from "./DeliveryTypes.module.scss";
import useWindowSize from "../../hooks/useWindowSize";

const DeliveryTypes = (props) => {
  const windowSize = useWindowSize();
  const tabletSize = 991;

  const typesItems = useMemo(
    () => [
      { imageSrc: busSVG, title: "Пассажирские перевозки" },
      { imageSrc: couchAndLampSVG, title: "Квартирные переезды" },
      {
        imageSrc: desktopComputerSVG,
        title: "Переезды офисов и бизнесов",
        mobileHTMLTitle: "Переезды офисов<br />и бизнесов",
      },
      {
        imageSrc: packageSVG,
        title: "Доставка товаров из магазинов",
        mobileHTMLTitle: "Доставка товаров<br />из магазинов",
      },
      {
        imageSrc: brickSVG,
        title: "Доставка товаров из магазинов",
        mobileHTMLTitle: "Доставка товаров<br />из магазинов",
      },
      { imageSrc: dogSVG, title: "Перевозка животных" },
      {
        imageSrc: cocktailSVG,
        title: "Перевозка хрупких грузов",
        mobileHTMLTitle: "Перевозка<br/>хрупких грузов",
      },
      {
        imageSrc: ladderSVG,
        title: "Перевозка<br/>нестандартных грузов",
        mobileHTMLTitle: "Перевозка<br/>нестандартных грузов",
      },
    ],
    []
  );

  return (
    <section className={styles.types}>
      <p className={styles.types__head}>Поможем с перевозкой любой сложности</p>
      <div className={styles["types__items-block"]}>
        {typesItems.map((item, i) => (
          <DeliveryTypesType
            imageSrc={item.imageSrc}
            className={styles.types__items}
            key={i}
          >
            {item.title}
          </DeliveryTypesType>
        ))}
      </div>
    </section>
  );
};

export default DeliveryTypes;
